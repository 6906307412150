import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaLinkedin } from 'react-icons/fa';
import logo from '../../assets/images/QA_proconsulting_logo .png';
import './navbar.css';

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div className={`navbar ${isMenuOpen ? 'open' : ''}`}>
      <div className="nav_left">
        <NavLink className='link_accueil' to='/'>
          Accueil
        </NavLink>
        <NavLink className='link_services' to='/Services'>
          Services
        </NavLink>
        <NavLink className='link_entreprise' to='/Entreprise'>
          Entreprise
        </NavLink>
      </div>
      <div className="element_logo">
        <NavLink to='/'>
          <img src={logo} alt="" className="logo" />
        </NavLink>
      </div>
      <div className="nav_right">
        <NavLink className='link_carriere' to='/Carriere'>
          Carrière
        </NavLink>
        <NavLink className='link_contact' to='/Contact'>
          Contact
        </NavLink>
        <NavLink className='link_falinkedin' to='https://www.linkedin.com/company/qa-pro-consulting/'>
          <FaLinkedin />
        </NavLink>
      </div>
      <div className="burger-icon" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <div className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
        <NavLink className='link_accueil' to='/' onClick={toggleMenu}>
          Accueil
        </NavLink>
        <NavLink className='link_services' to='/Services' onClick={toggleMenu}>
          Services
        </NavLink>
        <NavLink className='link_entreprise' to='/Entreprise' onClick={toggleMenu}>
          Entreprise
        </NavLink>
        <NavLink className='link_carriere' to='/Carriere' onClick={toggleMenu}>
          Carrière
        </NavLink>
        <NavLink className='link_contact' to='/Contact' onClick={toggleMenu}>
          Contact
        </NavLink>
        <NavLink className='link_falinkedin' to='https://www.linkedin.com/company/qa-pro-consulting/' onClick={toggleMenu}>
          <FaLinkedin />
        </NavLink>
      </div>
    </div>
  );
};

export default Navbar;
