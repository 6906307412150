import React from 'react'
import "./services.css"
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import Head from '../../components/head/Head'
import Card from '../../components/card/Card'
import c1 from '../../assets/images/consulting_images.png'
import s1 from '../../assets/service/test.jpeg'
import s2 from '../../assets/service/developpement.jpeg'
import s3 from '../../assets/service/cloud.jpeg'
import s4 from '../../assets/service/conseil.jpeg'
import s5 from '../../assets/service/satisfaction.jpeg'
import s6 from '../../assets/service/soustraitance.jpeg'
import TypingTitle from '../../components/typingtitle/TypingTitle'
import { NavLink } from 'react-router-dom'

const Services = () => {
  return (
    <div>
      <Navbar />
      <Head
        title1="Achieve Your Goals with Our Services"
        paragraph="Découvrez de nouvelles perspectives avec nos solutions 
        de transformation digitale. Votre parcours vers l'innovation commence 
        ici. Explorez nos services conçus pour propulser votre entreprise vers 
        l'ère numérique, en définissant la manière dont vous opérez, innovez 
        et prospérez dans un monde en constante évolution.
        "
        buttonText="En Savoir Plus"
        buttonLink="/Contact"
        backgroundImage="https://www.qs.com/wp-content/uploads/2018/10/iStock-916358344-1024x384.jpg"
      />
      <div className="title_services">
        <div className="part_text">
          <p className='paragraphe_title'>
            <h2>Entreprise de services du numérique</h2>
            Chez QA Pro Consulting, notre objectif est de vous fournir des prestations qui vont au-delà
            des attentes. Avec nos services de consultant, de conseil et de sous-traitance, nous vous
            accompagnons à chaque étape de votre transformation digitale.
            Découvrez comment nous pouvons aider votre entreprise à réaliser son plein potentiel numérique.
            Contactez-nous aujourd'hui pour discuter de vos besoins et explorer comment nous pouvons collaborer
            pour votre réussite.
          </p>
          <button >
            <NavLink to="/Contact" className="text_color">
              Contact
            </NavLink>
          </button>
        </div>
        <div className="part_img">
          <img src={c1} alt="consulting"/>
        </div>
      </div>
      <div className="content_card">
        <TypingTitle text="Expertises" />
        <p className='paragraphe_content'>
          Nous accompagnons nos clients dans leur parcours de la transformation technologique.
        </p>
        <div className="cards">
          <Card
            title="Test Logiciel et Assurance qualité"
            description="Nos experts de test logiciel garantissent la qualité, la performance 
            et la fiabilité de vos logiciels. Nous élaborons des stratégies de test approfondies et 
            rigoureuses pour assurer et renforcer la confiance auprès de nos clients."
            background={s1}
          />
          <Card
            title="Développement Opérationnel"
            description="Avec une approche axée sur le développement opérationnel, nous aidons nos 
            clients dans leur transformation technologique pour créer des solutions personnalisées. 
            De la conception à la mise en œuvre, notre équipe s'engage à optimiser les opérations et 
            à créer des applications performantes et évolutives."
            background={s2}
          />
          <Card
            title="Cloud"
            description="Notre expertise en migration vers le cloud facilite la transition vers une 
            infrastructure agile et évolutive. Nous planifions et exécutons la migration en exploitant 
            les avantages du cloud,  offrant flexibilité et sécurité améliorée."
            background={s3}
          />
        </div>
      </div>
      <div className="content_card">
        <TypingTitle text="Prestations" />
        <p className='paragraphe_content'>
        Nous sommes un partenaire technologique de confiance qui aide nos clients à réussir dans un monde numérique en constante évolution.
        </p>
        <div className="cards">
          <Card
            title="Consultant "
            description="Notre équipe d'experts-conseils en transformation digitale vous guide dans 
            l'élaboration de stratégies solides et innovantes. Nous évaluons vos besoins, identifions 
            les opportunités de croissance et définissons une feuille de route claire pour transformer 
            vos défis en avantages numériques."
            background={s4}
          />
          <Card
            title="Conseil en Technologie Avancée "
            description="En choisissant QA Pro Consulting, vous optez pour des conseils éclairés en matière 
            de technologie. Nous vous fournissons des informations précieuses sur les dernières tendances, 
            les meilleures pratiques et les solutions émergentes, vous aidant ainsi à prendre des décisions 
            éclairées pour votre entreprise."
            background={s5}
          />
          <Card
            title="Sous-Traitance de Projets Numériques "
            description="Notre équipe d'experts prend en charge la réalisation de vos projets, vous permettant 
            de rester concentré sur vos priorités métier. Avec une exécution précise et créative, nous 
            transformons vos idées en réalité numérique, tout en réduisant les coûts et en gagnant en efficacité."
            background={s6}
          />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Services