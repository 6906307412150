import React from 'react'
import "./accueil.css"
import Navbar from '../../components/navbar/Navbar'
import Head from '../../components/head/Head'
import Card from '../../components/card/Card'
import v1 from '../../assets/valeur/engagement.jpeg'
import v2 from '../../assets/valeur/qualite.jpeg'
import v3 from '../../assets/valeur/transparence.jpeg'
import v4 from '../../assets/valeur/satisfaction.jpeg'
import Footer from '../../components/footer/Footer'
import Content from '../../components/content/Content'
import TypingTitle from '../../components/typingtitle/TypingTitle'
import MainCircle from '../../components/maincircle/MainCircle'

const Accueil = () => {
  return (
    <div>
      <Navbar />
      <Head
        title1="Quality, is our priority"
        paragraph="QA Pro Consulting est un acteur de conseil en système informatique, 
        qui accompagne ses clients dans leur transformation digitale.Nous sommes engagés 
        à vous accompagner dans votre transformation technologique en garantissant la qualité, 
        en optimisant les processus DevOps et en exploitant les avantages du cloud.
        QA Pro Consulting est une ESN française riche dans son domaine IT. Nous mettons 
        à votre disposition notre expertise et notre réseau de consultants hautement qualifiés, 
        afin de vous aider à relever les défis techniques spécifiques.
        Notre équipe multidisciplinaire travaille en étroite collaboration pour assurer la qualité 
        et la performance de vos produits avec des stratégies de test complètes et des évaluations 
        rigoureuses, nous garantissons une expérience utilisateur optimale. 
        "
        buttonText="En Savoir Plus"
        buttonLink="/Contact"
        backgroundImage="https://www.alenium.ma/assets/images/slider/home-slider-2.jpg"
      />
      <div className="content_card">
        <TypingTitle text="Nos Valeurs" />
        <div className="cards">
          <Card
            title="Engagement"
            description="Une compréhension approfondie, des expériences enrichissantes, des 
            solutions sur mesure et une collaboration active avec notre équipe. Notre engagement"
            background={v1}
          />
          <Card
            title="Qualité de nos services"
            description="La qualité est notre priorité absolue. Nous 
            appliquons des processus rigoureux de sélection et 
            d'évaluation des consultants, en utilisant des 
            méthodologies telles que l'évaluation des compétences."
            background={v2}
            

          />
          <Card
            title="Transparence de nos échanges"
            description="Une communication ouverte, partage, défis, 
            solutions, pour construire une relation de confiance solide 
            avec nos clients"
            background={v3}

          />
          <Card
            title="Satisfaction client"
            description="Chaque étape de notre travail vise à répondre 
            à vos besoins et à dépasser vos attentes. Notre engagement 
            envers votre satisfaction guide notre approche et stimule 
            notre recherche constante de l'excellence."
            background={v4}
          />
        </div>
      </div>
      <div className="container">
        <div className="flex_content">
          <Content
            title="Entreprise"
            description="En tant que partenaire stratégique, nous collaborons 
            étroitement pour répondre à vos besoins technologiques. De la 
            conception de solutions sur mesure à la formation du personnel, 
            nous assurons la qualité de vos projets et vous soutenons tout au 
            long de notre partenariat.
            "
            backgroundImage="https://cdn.pixabay.com/photo/2014/12/08/21/25/innovation-561388_1280.jpg"
          />
          <Content
            title="Recrutement"
            description="Chez QA Pro Consulting, nous sommes toujours à la recherche 
            de talents exceptionnels dans la transformation digitale pour rejoindre 
            notre équipe. Notre processus de recrutement est conçu pour identifier 
            des professionnels passionnés, créatifs et compétents qui contribueront 
            à notre mission d'excellence technologique. Vous êtes quelqu’un d’autonome, de rigoureux, compétitif, vous aimez aller de l’avant ?"
            background="https://img.over-blog-kiwi.com/1/91/62/47/20151210/ob_3bf227_fotolia-43728429-xs.jpg"
          />
        </div>
      </div>
      <MainCircle  />
     
      <Footer />
    </div>
  )
}

export default Accueil