import React from 'react'
import "./carriere.css"
import Navbar from '../../components/navbar/Navbar'

import c1 from '../../assets/images/carriere/epanoussement.jpeg'
import c2 from '../../assets/images/carriere/opportunite.jpeg'
import c3 from '../../assets/images/carriere/defis.jpeg'
import c4 from '../../assets/images/carriere/competences.jpeg'
import c5 from '../../assets/images/carriere/partenariats.jpeg'
import c6 from '../../assets/images/carriere/reseau.jpeg'
import c7 from '../../assets/images/carriere/expertise.jpeg'
import Footer from '../../components/footer/Footer'
import Head from '../../components/head/Head'
import Offre from '../../components/offre/Offre'
import TypingTitle from '../../components/typingtitle/TypingTitle'


const Carriere = () => {

  const regionsFrance = [
    "Auvergne-Rhône-Alpes",
    "Bourgogne-Franche-Comté",
    "Bretagne",
    "Centre-Val de Loire",
    "Corse",
    "Grand Est",
    "Hauts-de-France",
    "Île-de-France",
    "Normandie",
    "Nouvelle-Aquitaine",
    "Occitanie",
    "Pays de la Loire",
    "Provence-Alpes-Côte d'Azur"
  ];


  return (
    <div>
      <Navbar />
      <Head
        title1="Tracez votre propre chemin avec QA Pro Consulting"
        paragraph="QA Pro Consulting aide les entreprises à garantir la qualité de leurs produits et 
        services numériques. Nous travaillons avec des clients de différents secteurs pour les aider à 
        atteindre leurs objectifs commerciaux et à améliorer leur efficacité opérationnelle. Nous sommes 
        passionnés par l’innovation et nous sommes fiers de notre capacité à aider nos clients à innover et à se développer. 
        Explorez nos opportunités de carrière et rejoignez-nous pour un voyage passionnant au cœur de la technologie."
        buttonText="En Savoir Plus"
        buttonLink="/Contact"
        backgroundImage="https://cdn.pixabay.com/photo/2015/01/09/11/08/startup-594090_1280.jpg"
      />
      <div className="title_carriere">
        <h1>QA Pro Consulting est plus qu'une entreprise et c'est exactement la raison pour laquelle vous devriez nous rejoindre.</h1>
        <div className="section_content">
          <div className="section_block">
            <div className="block_content">
              <div className="block_container">
                <div className="img_container">
                  <img src={c1} alt="" className='img' />
                </div>
                <div className='txt_container'>
                  <p style={{ textAlign: 'justify' }}>
                    <h3>Épanouissement professionnel </h3>
                    <p>Environnement stimulant favorisant la collaboration, la créativité et l'excellence.</p>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="section_block">
            <div className="block_content">
              <div className="block_container">
                <div className="img_container">
                  <img src={c2} alt="" className='img' />
                </div>
                <div className='txt_container'>
                  <p style={{ textAlign: 'justify' }}>
                    <h3>Opportunités variées  </h3>
                    <p>Projets passionnants et variés, collaboration avec des esprits brillants, développement continu.</p>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="section_block">
            <div className="block_content">
              <div className="block_container">
                <div className="img_container">
                  <img src={c3} alt="" className='img' />
                </div>
                <div className='txt_container'>
                  <p style={{ textAlign: 'justify' }}>
                    <h3>Défis Captivants</h3>
                    <p>Relevez des défis techniques captivants et évoluez au sein d'une communauté façonnant l'avenir numérique.</p>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="section_block">
            <div className="block_content">
              <div className="block_container">
                <div className="img_container">
                  <img src={c4} alt="" className='img' />
                </div>
                <div className='txt_container'>
                  <p style={{ textAlign: 'justify' }}>
                    <h3>Compétences Accélérées</h3>
                    <p>Montée en compétences rapide, missions variées dans divers secteurs d'activité.</p>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="section_block">
            <div className="block_content">
              <div className="block_container">
                <div className="img_container">
                  <img src={c5} alt="" className='img' />
                </div>
                <div className='txt_container'>
                  <p style={{ textAlign: 'justify' }}>
                    <h3>Partenariats diversifiés</h3>
                    <p>Collaborations avec des clients de secteurs variés, optimisation des objectifs et de l'efficacité.</p>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="section_block">
            <div className="block_content">
              <div className="block_container">
                <div className="img_container">
                  <img src={c6} alt="" className='img' />
                </div>
                <div className='txt_container'>
                  <p style={{ textAlign: 'justify' }}>
                    <h3>Réseau et Perspectives</h3>
                    <p>Développement de réseau, opportunités d'avenir enrichissantes.</p>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="section_block">
            <div className="block_content">
              <div className="block_container">
                <div className="img_container">
                  <img src={c7} alt="" className='img' />
                </div>
                <div className='txt_container'>
                  <p style={{ textAlign: 'justify' }}>
                    <h3>Expertise Reconnue</h3>
                    <p>Vous aurez la chance de travailler sur des technologies de pointe et être reconnu comme un expert chez les clients.</p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="offre_content">
        <TypingTitle text="Nos offres d'emplois" />
        <div className="offre_container">
          <form action="" method="">
            <select style={{ width: '200px', height: '40px', marginRight: '10px' }}>
              <option value="" disabled selected hidden>Choisir une région</option>
              {regionsFrance.map((region, index) => (
                <option key={index} value={region}>
                  {region}
                </option>
              ))}
            </select>
            <select style={{ width: '200px', height: '40px', marginRight: '10px' }}>
              <option value="" disabled selected hidden>Choisir un type de contrat</option>
              <option value="region1">CDI</option>
              <option value="region2">CDD</option>
              <option value="region3">Freelance</option>
            </select>
            <button type="submit">
              Recherche
            </button>
          </form>
        </div>
      </div>
      <Offre />
      <div className="candidature_content">
        <TypingTitle text="Candidature Spontanée" />
        <form action="" method=''>
          <input type="text" placeholder='poste recherché' className='input_poste' required />
          <h4>Votre CV</h4>
          <input type="file" placeholder='sélectionner un fichier' required />
          <div className="candidature">
            <h4>Vos Informations Personnelles</h4>
            <div className="candidature_container">
              <div className='candidature_left'>
                <input type="text" placeholder='Nom' required />
                <input type="text" placeholder='Civilité' required />
                <input type="text" placeholder='Téléphone' required />
                <input type="text" placeholder='Adresse Complète' required />
                <input type="text" placeholder="Niveau d'étude" required />
              </div>
              <div className='candidature_right'>
                <input type="text" placeholder='Prenom' required />
                <input type='email' placeholder='Email' required />
                <input type="text" placeholder='Pays' required />
                <input type="text" placeholder='Experiences' required />
                <input type="text" placeholder='Type de contrat' required />
              </div>
            </div>
            <button type="submit">
              Postuler
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  )
}

export default Carriere