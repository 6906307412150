import React from 'react';
import "./post.css";

const Post = (props) => {
    const { title, location, salary, telework, offerType, geoZone, voirPlus, reference } = props;

    return (
        <div className='post_content'>
            <h2>{title}</h2>
            <div className="post_container">
                <div>
                    <p>Lieu : {location}</p>
                    <p>Salaire : {salary}</p>
                    <p>Rythme : {telework}</p>
                    <p>{voirPlus}</p>
                </div>
                <div>
                    <p>Offre : {offerType}</p>
                    <p>Zone Géo : {geoZone}</p>
                </div>
                <div>
                    <p>{reference}</p>
                </div>
            </div>
        </div>
    );
}

export default Post;
