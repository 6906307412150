import React from 'react';
import "./card.css"

const Card = ({ title, description, background }) => {
    const cardStyle = {
        backgroundImage: `url(${background})`, // Utilisez l'URL de l'image de fond
        backgroundSize: '100%',
    };

    return (
        <div className='card_content' style={cardStyle}>
            <div className='card_container'>
                <div className='card_front'>
                    <h2>{title}</h2>
                </div>
                <div className='card_back'>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    )
}

export default Card;
