import React from 'react';
import './content.css';

const Content = ({ title, description }) => {
    return (
        <div className='element_content'>
            <div className='content_container'>
                <h2 className='text_align'>{title}</h2>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default Content;
