import React from 'react'
import "./contact.css"
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import Head from '../../components/head/Head'
import TypingTitle from '../../components/typingtitle/TypingTitle'

const Contact = () => {
  return (
    <div>
      <Navbar />
      <Head
        title1="WORKING TOGETHER WITH QA PRO CONSULTING"
        paragraph="Nous sommes là pour vous, pour toute question, renseignement ou opportunité de collaboration. 
        Votre message est important pour nous, et nous avons hâte de vous fournir une réponse attentive. Utilisez 
        les informations ci-dessous pour nous joindre."
        buttonText="En Savoir Plus"
        buttonLink="/Contact"
        backgroundImage="https://cdn.pixabay.com/photo/2015/01/09/11/11/office-594132_1280.jpg"
      />
      <div className="content_contact">
        <TypingTitle text="Contactez-Nous" />
        <div className="container_contact">
          <div className='contact_left'>
            <form action="">
              <div className='contact'>
                <input type="text" placeholder='Nom' required />
                <input type="email" placeholder='Email' required />
                <input type="text" placeholder='Numéro de téléphone' required />
                <input type="text" placeholder="Nom de l'entreprise" required />
                <input type="text" placeholder="Type de l'entreprise"  required/>
                <textarea cols="30" rows="10" placeholder='Votre message' required></textarea>
                <button type="submit" className='btn_contact'>
                  Envoyer
                </button>
              </div>
            </form>
          </div>
          <div className='contact_right'>
            <h4>Contact Information </h4>
            <p>3 RUE DES RONDONNEAUX</p>
            <p>75020 PARIS</p>
            <p>(+33) 06 09 36 95 88</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Contact