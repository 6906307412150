// SmallCircle.js
import React from 'react';
import './smallcircle.css';

const SmallCircle = ({ title, onMouseOver, onMouseOut }) => {
  return (
    <div className="small-circle" onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      {title}
    </div>
  );
};

export default SmallCircle;
