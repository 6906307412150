import React, { useState } from 'react';
import './head.css';

const Head = ({ title1, title2, paragraph, buttonText, buttonLink, backgroundImage, }) => {
    const [showFullParagraph, setShowFullParagraph] = useState(false);
    const headStyle = {
        backgroundImage: `url(${backgroundImage})`
    };

    const toggleParagraph = () => {
        setShowFullParagraph(!showFullParagraph);
    };

    return (
        <div className='head_content' style={headStyle}>
            <div className="color_content">
                <div className="head_container">
                    <h1>{title1}</h1>
                    <h1>{title2}</h1>
                    <p>{showFullParagraph ? paragraph : `${paragraph.slice(0, 300)}...`}</p>
                    <div className="">
                        <button className='btn_head' onClick={toggleParagraph}>
                            {buttonText}
                        </button>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default Head;
