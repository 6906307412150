import React, { useState } from 'react';
import SmallCircle from '../smallcircle/SmallCircle';
import './maincircle.css';
import ParticlesBackground from '../particlesbackground/ParticlesBackground';

const MainCircle = () => {
    const [hoveredCircle, setHoveredCircle] = useState(null);

    const smallCircles = [
        { id: 1, title: 'Expertise', description: 'Notre équipe possède une expertise pointue dans la transformation digitale. Nous maîtrisons les dernières technologies et les meilleures pratiques pour concevoir des solutions innovantes et adaptées' },
        { id: 2, title: 'Performance ', description: 'La performance est notre norme. Chaque projet que nous livrons est rigoureusement testé pour garantir la rapidité, la stabilité et la fiabilité exceptionnelle.' },
        { id: 3, title: 'Flexibilité', description: 'Nos solutions sont taillées sur mesure pour vos besoins. Nous nous adaptons aux changements et intégrons les nouvelles technologies pour une solution toujours alignée sur votre entreprise.' },
        { id: 4, title: 'Support', description: 'Nous assurons un support complet à long terme. Notre équipe est toujours disponible pour résoudre les problèmes, fournir des conseils et assurer une expérience client sans faille.' },
        // ... d'autres petits cercles
    ];

    return (
        <div className='content_circle'>
            <ParticlesBackground className='particule_position' />
            <div className="main-circle">
                <div className="description">
                    {hoveredCircle ? hoveredCircle.description : 'Nos points forts'}
                </div>
                {smallCircles.map(circle => (
                    <SmallCircle
                        key={circle.id}
                        title={circle.title}
                        onMouseOver={() => setHoveredCircle(circle)}
                        onMouseOut={() => setHoveredCircle(null)}
                    />
                ))}
            </div>
        </div>
    );
};

export default MainCircle;
