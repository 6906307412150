import React from 'react'
import "./entreprise.css"
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import Head from '../../components/head/Head'
import e1 from '../../assets/images/entreprise/qualite.jpeg'
import e2 from '../../assets/images/entreprise/conseil.jpeg'
import e3 from '../../assets/images/entreprise/developpement.jpeg'
import e4 from '../../assets/images/entreprise/cloud.jpeg'
import e5 from '../../assets/images/entreprise/expertise.jpeg'
import e6 from '../../assets/images/entreprise/orientation.jpeg'
import e7 from '../../assets/images/entreprise/cout.jpeg'
import e8 from '../../assets/images/entreprise/assistance.jpeg'
import e9 from '../../assets/images/entreprise/flexibilite.jpeg'
import iStock from "../../assets/images/iStock.jpeg"
import Technologie from "../../assets/images/Technologie.jpeg"
import TypingTitle from '../../components/typingtitle/TypingTitle'
import { NavLink } from 'react-router-dom'

const Entreprise = () => {
  return (
    <div>
      <Navbar />
      <Head
        title1="QA Pro Consulting
        CONSEILLER, CONCEVOIR, RÉALISER, OPÉRER"
        paragraph="Notre expertise en transformation digitale s'étend à une variété de secteurs d'activité. 
        Nous travaillons avec des entreprises dans des domaines tels que l’industrie, le commerce, l'énergie, 
        la finance et la santé...etc. Chaque secteur a ses propres défis uniques et c'est là que notre savoir-faire 
        entre en jeu. Nous adaptons nos solutions pour répondre aux besoins spécifiques de chaque client, créant 
        ainsi des résultats tangibles et durables. Quel que soit votre secteur, notre engagement envers l'innovation 
        et l'excellence vous aide à prospérer dans l'ère numérique en constante évolution."
        buttonText="En Savoir Plus"
        buttonLink="/Contact"
        backgroundImage="https://www.mastersbooking.fr/sites/default/files/styles/img_blog/public/field/image/conseil-strategie-mastersbooking.jpg?itok=unH9_lzc"
      />
      <div className="content_card">
        <TypingTitle text="Domaines d'interventions" />
        <div className='content_box'>
          <div className='container_box'>
            <div className='box_left'>
              <div className="section_block">
                <div className="post_box">
                  <div className="post_container">
                    <div className="img_post">
                      <img src={e1} alt="" className='img' />
                    </div>
                    <div className='text_post'>
                      <p style={{ textAlign: 'justify' }}>
                        <h3>Qualité Assurance </h3>
                        <p>
                          fournir des services de qualité assurance pour aider nos
                          clients à garantir la qualité de leurs produits et services informatiques, de la planification,
                          l’exécution de tests de logiciels, la gestion de la qualité des données et des
                          systèmes de l’information.
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
                <br />
                <div className="post_box">
                  <div className="post_container">
                    <div className="img_post">
                      <img src={e2} alt="" className='img' />
                    </div>
                    <div className='text_post'>
                      <p style={{ textAlign: 'justify' }}>
                        <h3>Conseil en technologie</h3>
                        <p>
                          nous offrons des conseils stratégiques basés  sur notre
                          expertise approfondie et notre compréhension des dernières tendances technologiques
                          pour vous aider à utiliser au mieux les technologies en fonction de vos objectifs.
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
                <br />
                <div className="post_box">
                  <div className="post_container">
                    <div className="img_post">
                      <img src={e3} alt="" className='img' />
                    </div>
                    <div className='text_post'>
                      <p style={{ textAlign: 'justify' }}>
                        <h3>Développement de logiciel</h3>
                        <p>
                          joue un rôle clé dans la transformation numérique et la
                          création de solutions innovantes. Ces services peuvent inclure la planification, la
                          conception, le développement, les tests et la maintenance de logiciels.
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
                <br />
                <div className="post_box">
                  <div className="post_container">
                    <div className="img_post">
                      <img src={e4} alt="" className='img' />
                    </div>
                    <div className='text_post'>
                      <p style={{ textAlign: 'justify' }}>
                        <h3>Cloud computing</h3>
                        <p>
                          Nous facilitons la migration vers le cloud et gérons vos solutions
                          cloud, offrant une flexibilité accrue, une échelle rapide et un accès à vos données depuis n'importe où.
                          De la préparation à la mise en production. Nous configurons et gérons vos serveurs, vos sauvegardes, vos
                          données tout en améliorant l'agilité, favorisant l'innovation et transformant la manière dont les ressources technologiques sont gérées et utilisées.
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
                <br />
              </div>
              <button>
                <NavLink to="/Contact" className="link_btn">
                  Contact
                </NavLink>
              </button>
            </div>
            <div className='box_right'>
              <img src={Technologie} width="100%" alt='' />
            </div>
          </div>
        </div>
        <TypingTitle text="Les avantages pour les entreprises" />
        <div className='content_box'>
          <div className='container_box'>
            <div className='box_left'>
              <img src={iStock} width="100%" alt='' />
            </div>
            <div className='box_right'>

              <div className="post_box">
                <div className="post_container">
                  <div className="img_post">
                    <img src={e5} alt="" className='img' />
                  </div>
                  <div className='text_post'>
                    <p style={{ textAlign: 'justify' }}>
                      <h3>Expertise</h3>
                      <p>
                        Bénéficier de l’expérience et de l’expertise du consultant dans de nombreux secteurs.
                      </p>
                    </p>
                  </div>
                </div>
              </div>
              <br />
              <div className="post_box">
                <div className="post_container">
                  <div className="img_post">
                    <img src={e6} alt="" className='img' />
                  </div>
                  <div className='txt_container'>
                    <p style={{ textAlign: 'justify' }}>
                      <h3>Orientation</h3>
                      <p>
                        Guider les entreprises à travers les défis et les situations liés à leurs stratégies et leur système informatique.
                      </p>
                    </p>
                  </div>
                </div>
              </div>
              <br />
              <div className="post_box">
                <div className="post_container">
                  <div className="img_post">
                    <img src={e7} alt="" className='img' />
                  </div>
                  <div className='text_post'>
                    <p style={{ textAlign: 'justify' }}>
                      <h3>Réduction des coûts et gain de temps</h3>
                      <p>
                        En identifiant et en corrigeant les problèmes dès le début du processus,
                        l'entreprise peut réduire les coûts liés aux défaillances du logiciel afin de gagner du temps.
                      </p>
                    </p>
                  </div>
                </div>
              </div>
              <br />
              <div className="post_box">
                <div className="post_container">
                  <div className="img_post">
                    <img src={e8} alt="" className='img' />
                  </div>
                  <div className='text_post'>
                    <p style={{ textAlign: 'justify' }}>
                      <h3>Assistance Qualité Logicielle</h3>
                      <p>
                        nous vous aiderons à pallier les difficultés
                        liées à la qualité de vos logiciels en définissant votre stratégie de test unique, test fonctionnel, automatisation, performance.
                      </p>
                    </p>
                  </div>
                </div>
              </div>
              <br />
              <div className="post_box">
                <div className="post_container">
                  <div className="img_post">
                    <img src={e9} alt="" className='img' />
                  </div>
                  <div className='text_post'>
                    <p style={{ textAlign: 'justify' }}>
                      <h3>La flexibilité</h3>
                      <p>
                        elle implique la capacité à nous adapter aux besoins changeants et
                        aux exigences spécifiques de notre client tout au long du projet ou de la collaboration
                        afin que notre client bénéficie de nos services de manière flexible en fonction de leurs besoins.
                      </p>
                    </p>
                  </div>
                </div>
              </div>
              <br />
              <button>
                <NavLink to="/Contact" className="link_btn">
                  Contact
                </NavLink>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Entreprise