import React from 'react'
import './offre.css'
import Post from '../post/Post'

const Offre = () => {
    return (
        <div className="offre_element">
            <div className='content_offre'>
                <div className="container_offre">
                    <section>
                        <Post
                            title="ADMINISTRATEUR SYSTEM ET RESEAU H/F"
                            location="Sud de Lens"
                            salary="32 à 38 k annuel brut"
                            telework="Jusqu'a 2 jours de télétravail par semaine"
                            offerType="CDI"
                            geoZone="Sud de Lens"
                            reference="A023"
                        />
                    </section>
                    <section>
                        <Post
                            title="TECHNICIEN DE SUPPORT APPLICATIF H/F"
                            location="Brest"
                            salary="42 à 58 k annuel brut"
                            voirPlus="voir +"
                            telework="Jusqu'a 1 jours de télétravail par semaine"
                            offerType="Consultant"
                            geoZone="Brest"
                            reference="A024"
                        />
                    </section>
                    <section>
                        <Post
                            title="DEVOPS H/F"
                            location="Toulouse"
                            salary="61 à 82 k annuel brut"
                            voirPlus="voir +"
                            telework="Jusqu'a 3 jours de télétravail par semaine"
                            offerType="CDD"
                            geoZone="Toulouse"
                            reference="A025"
                        />
                    </section>
                    <section>
                        <Post
                            title="DEVELOPPEMENT WEB H/F"
                            location="Paris"
                            salary="53 à 75 k annuel brut"
                            voirPlus="voir +"
                            telework="Jusqu'a 4 jours de télétravail par semaine"
                            offerType="CDI"
                            geoZone="Paris"
                            reference="A026"
                        />
                    </section>
                    <section>
                        <Post
                            title="DEVELOPPEUR BACKEND H/F"
                            location="Limoges"
                            salary="34 à 56 k annuel brut"
                            voirPlus="voir +"
                            telework="Jusqu'a 1 jours de télétravail par semaine"
                            offerType="CDD"
                            geoZone="Limoges"
                            reference="A027"
                        />
                    </section>
                    <section>
                        <Post
                            title="DEVELOPPEUR BACKEND H/F"
                            location="Limoges"
                            salary="34 à 56 k annuel brut"
                            voirPlus="voir +"
                            telework="Jusqu'a 1 jours de télétravail par semaine"
                            offerType="CDD"
                            geoZone="Limoges"
                            reference="A027"
                        />
                    </section>
                    <section>
                        <Post
                            title="DEVELOPPEUR BACKEND H/F"
                            location="Limoges"
                            salary="34 à 56 k annuel brut"
                            voirPlus="voir +"
                            telework="Jusqu'a 1 jours de télétravail par semaine"
                            offerType="CDD"
                            geoZone="Limoges"
                            reference="A027"
                        />
                    </section>
                    <section>
                        <Post
                            title="DEVELOPPEUR BACKEND H/F"
                            location="Limoges"
                            salary="34 à 56 k annuel brut"
                            voirPlus="voir +"
                            telework="Jusqu'a 1 jours de télétravail par semaine"
                            offerType="CDD"
                            geoZone="Limoges"
                            reference="A027"
                        />
                    </section>
                    <section>
                        <Post
                            title="DEVELOPPEUR BACKEND H/F"
                            location="Limoges"
                            salary="34 à 56 k annuel brut"
                            voirPlus="voir +"
                            telework="Jusqu'a 1 jours de télétravail par semaine"
                            offerType="CDD"
                            geoZone="Limoges"
                            reference="A027"
                        />
                    </section>
                    <section>
                        <Post
                            title="DEVELOPPEUR BACKEND H/F"
                            location="Limoges"
                            salary="34 à 56 k annuel brut"
                            voirPlus="voir +"
                            telework="Jusqu'a 1 jours de télétravail par semaine"
                            offerType="CDD"
                            geoZone="Limoges"
                            reference="A027"
                        />
                    </section>
                    <section>
                        <Post
                            title="DEVELOPPEUR BACKEND H/F"
                            location="Limoges"
                            salary="34 à 56 k annuel brut"
                            voirPlus="voir +"
                            telework="Jusqu'a 1 jours de télétravail par semaine"
                            offerType="CDD"
                            geoZone="Limoges"
                            reference="A027"
                        />
                    </section>
                </div>

            </div>
        </div>


    )
}

export default Offre