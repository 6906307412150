import React from 'react'
import './App.css'
import { Route, Routes } from "react-router-dom";
import Accueil from "./pages/accueil/Accueil";
import Services from './pages/services/Services';
import Entreprise from './pages/entreprise/Entreprise';
import Carriere from './pages/carriere/Carriere';
import Contact from './pages/contact/Contact';

const App = () => {
  return (
    <div className="App">
    <Routes>
      <Route path="/" element={<Accueil />} />
      <Route path="/services" element={<Services />} />
      <Route path="/entreprise" element={<Entreprise />} />
      <Route path="/carriere" element={<Carriere />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  </div>
  )
}

export default App