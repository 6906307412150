import React, { useState, useEffect } from 'react';

function TypingTitle({ text }) {
    const [typedText, setTypedText] = useState('');

    useEffect(() => {
        let currentIndex = 0;
        const typingInterval = setInterval(() => {
            if (currentIndex <= text.length) {
                setTypedText(text.slice(0, currentIndex));
                currentIndex++;
            } else {
                clearInterval(typingInterval);
            }
        }, 100);

        return () => {
            clearInterval(typingInterval);
        };
    }, [text]);

    return (
        <h1>{typedText}</h1>
    );
}

export default TypingTitle;
