import React from 'react'
import "./footer.css"
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='footer'>
      <div className="left_element">
        <p>Faites nous part de votre intéret</p>
        <p>Copyright © QA ProConsulting</p>
      </div>
      <div className="right_element">
        <div className='button_element'>
          <button className='style_btn'>
            <NavLink to="/Contact" className="link_btn">
              Contact
            </NavLink>
          </button>
        </div>
        <div className='link'>
          <NavLink className='link_left'>Politique de Confidentialité</NavLink>
          <NavLink className='link_right'>Conditions d'utilisations</NavLink>
        </div>
      </div>
    </div>
  )
}

export default Footer